'use client';
import React, { useRef, useMemo } from 'react';
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
  OverlayView,
} from '@react-google-maps/api';
import Button from '@ui/components/ui/button/Button';
import { Icon } from '@icons/icons';
import { env } from '../../env';

type GeneralGoogleMapData = {
  name: string;
  lat: number;
  lng: number;
};

export type AirportGoogleMapData = GeneralGoogleMapData & {
  providers?: Array<GeneralGoogleMapData>;
};

export type ProviderGoogleMapData = {
  name: string;
  lat: number;
  lng: number;
  price?: number;
  details?: string;
  description?: string;
};

type GoogleMapsProps = {
  selectedCountry: string;
  selectedAirport: AirportGoogleMapData | undefined;
  filteredAirports?: Array<AirportGoogleMapData>;
  providerData?: Array<ProviderGoogleMapData>;
  providerOptionData?: Array<ProviderGoogleMapData>;
  onItemSelect: (item: AirportGoogleMapData | undefined) => void;
};

const containerStyle = {
  width: '100%',
  height: '100%',
};
const mapStyle = [
  {
    featureType: 'administrative.country',
    elementType: 'labels.text',
    stylers: [
      {
        lightness: '29',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#8c9095' }, { weight: 1.5 }],
  },
  {
    featureType: 'administrative.province',
    elementType: 'labels.text.fill',
    stylers: [
      {
        lightness: '-12',
      },
      {
        color: '#796340',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [
      {
        lightness: '15',
      },
      {
        saturation: '15',
      },
    ],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#fbf5ed',
      },
    ],
  },
  {
    featureType: 'landscape.natural',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#fbf5ed',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.attraction',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
      {
        lightness: '30',
      },
      {
        saturation: '-41',
      },
      {
        gamma: '0.84',
      },
    ],
  },
  {
    featureType: 'poi.attraction',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi.business',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.business',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.medical',
    elementType: 'geometry',
    stylers: [
      {
        color: '#fbd3da',
      },
    ],
  },
  {
    featureType: 'poi.medical',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#b0e9ac',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        hue: '#68ff00',
      },
      {
        lightness: '-24',
      },
      {
        gamma: '1.59',
      },
    ],
  },
  {
    featureType: 'poi.sports_complex',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi.sports_complex',
    elementType: 'geometry',
    stylers: [
      {
        saturation: '10',
      },
      {
        color: '#c3eb9a',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        lightness: '30',
      },
      {
        color: '#e7ded6',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
      {
        saturation: '-39',
      },
      {
        lightness: '28',
      },
      {
        gamma: '0.86',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffe523',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        saturation: '0',
      },
      {
        gamma: '1.44',
      },
      {
        color: '#fbc28b',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
      {
        saturation: '-40',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#fed7a5',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'on',
      },
      {
        gamma: '1.54',
      },
      {
        color: '#fbe38b',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        visibility: 'on',
      },
      {
        gamma: '2.62',
      },
      {
        lightness: '10',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        weight: '0.50',
      },
      {
        gamma: '1.04',
      },
    ],
  },
  {
    featureType: 'transit.station.airport',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#dee3fb',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        saturation: '46',
      },
      {
        color: '#a4e1ff',
      },
    ],
  },
];

export const GoogleMaps: React.FC<GoogleMapsProps> = ({
  selectedCountry,
  selectedAirport,
  providerData,
  providerOptionData,
  filteredAirports,
  onItemSelect,
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: env.NEXT_PUBLIC_SITE_GOOGLE_MAPS_API,
  });

  const mapRef = useRef<google.maps.Map | null>(null);
  const directionsRendererRef = useRef<google.maps.DirectionsRenderer | undefined>(
    undefined
  );
  const [error, setError] = React.useState<string | null>();
  const [nearestCountry, setNearestCountry] =
    React.useState<string>(selectedCountry);

  const [travelInfo, setTravelInfo] = React.useState<{
    distance: string;
    duration: string;
    eta: string;
  } | null>();

  const [selectedProvider, setSelectedProvider] = React.useState<{
    name: string;
    lat: number;
    lng: number;
    details?: string;
    description?: string;
    price?: number;
  } | null>();

  const [selectedProviderOption, setSelectedProviderOption] = React.useState<{
    name: string;
    lat: number;
    lng: number;
    details?: string;
    description?: string;
    price?: number;
  } | null>();

  const [hoveredProvider, setHoveredProvider] = React.useState<{
    name: string;
    lat: number;
    lng: number;
  } | null>();

  const center = useMemo(() => {
    if (filteredAirports?.length) {
      const avgLat =
        filteredAirports.reduce((sum, airport) => sum + airport.lat, 0) /
        filteredAirports.length;
      const avgLng =
        filteredAirports.reduce((sum, airport) => sum + airport.lng, 0) /
        filteredAirports.length;

      return { lat: avgLat, lng: avgLng, zoom: 6 };
    }
    if (providerData?.length) {
      const avgLat =
        providerData.reduce((sum, airport) => sum + airport.lat, 0) /
        providerData.length;
      const avgLng =
        providerData.reduce((sum, airport) => sum + airport.lng, 0) /
        providerData.length;

      return { lat: avgLat, lng: avgLng, zoom: 12 };
    }
    if (providerOptionData?.length) {
      const avgLat =
        providerOptionData.reduce((sum, airport) => sum + airport.lat, 0) /
        providerOptionData.length;
      const avgLng =
        providerOptionData.reduce((sum, airport) => sum + airport.lng, 0) /
        providerOptionData.length;

      return { lat: avgLat, lng: avgLng, zoom: 12 };
    }

    // Default center (fallback location)
    return { lat: 52.310_538, lng: 4.768_274, zoom: 6 };
  }, [filteredAirports, providerData, providerOptionData]);

  const mapOptions = useMemo(
    () => ({
      styles: mapStyle,
      disableDefaultUI: true,
      zoomControl: true,
      fullscreenControl: false,
      mapTypeControl: true,
      streetViewControl: true,
      rotateControl: true,
    }),
    []
  );

  const pinIcon = React.useMemo(() => {
    if (!isLoaded) return;
    return {
      url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
        <svg width="20" height="28" viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 28C10 28 0 15.218 0 9.8C-1.95685e-08 8.51305 0.258658 7.23869 0.761205 6.0497C1.26375 4.86071 2.00035 3.78037 2.92893 2.87035C3.85752 1.96034 4.95991 1.23848 6.17317 0.745981C7.38642 0.253484 8.68678 0 10 0C11.3132 0 12.6136 0.253484 13.8268 0.745981C15.0401 1.23848 16.1425 1.96034 17.0711 2.87035C17.9997 3.78037 18.7363 4.86071 19.2388 6.0497C19.7413 7.23869 20 8.51305 20 9.8C20 15.218 10 28 10 28ZM9.48718 9.8C10.2449 9.8 8.95593 10.2751 9.49175 9.75C10.0276 9.2249 9.48718 10.5426 9.48718 9.8C9.48718 9.05739 12.5561 8.3452 12.0203 7.8201C11.4845 7.295 10.7578 7 10 7C9.24224 7 8.51551 7.295 7.9797 7.8201C7.44388 8.3452 9.48718 4.75739 9.48718 5.5C9.48718 6.24261 12.5411 6.7249 13.0769 7.25C13.6127 7.7751 8.72942 9.8 9.48718 9.8Z" fill="#12A3FD"/>
          <g clip-path="url(#clip0_243_312)">
          <path d="M5.8156 9.49307L7.86332 10.0695L10.114 8.47571L6.88398 8.56599C6.85235 8.56767 6.8217 8.57723 6.79494 8.59376L5.77286 9.16668C5.74107 9.18385 5.71529 9.20993 5.69887 9.24155C5.68244 9.27316 5.67612 9.30885 5.68072 9.344C5.68533 9.37916 5.70064 9.41215 5.72468 9.43873C5.74873 9.4653 5.7804 9.48423 5.8156 9.49307Z" fill="white"/>
          <path d="M15.8833 7.70767L13.6397 7.23544C13.451 7.1955 13.2557 7.19561 13.067 7.23575C12.8784 7.27589 12.7008 7.35513 12.5464 7.46808L5.76929 12.2702L4.1418 12.2007C4.04858 12.1976 3.95672 12.2232 3.87919 12.2737C3.80166 12.3243 3.74237 12.3973 3.70971 12.4825C3.67704 12.5677 3.67265 12.6607 3.69714 12.7485C3.72164 12.8362 3.77378 12.9143 3.84622 12.9716L5.43097 14.1972C5.45706 14.2178 5.48812 14.2314 5.52117 14.2369C5.55421 14.2424 5.58815 14.2395 5.61972 14.2285C6.072 14.0584 7.75647 13.1868 9.74722 12.1209L10.1461 16.1174C10.1495 16.152 10.1625 16.1851 10.1837 16.213C10.2049 16.2409 10.2336 16.2627 10.2666 16.2759C10.2996 16.2891 10.3356 16.2933 10.3709 16.288C10.4061 16.2827 10.4392 16.2681 10.4666 16.2459L11.3569 15.5236C11.4056 15.4838 11.4384 15.4285 11.4495 15.3674L12.2935 10.7528C13.718 9.98197 15.0784 9.23544 16.0364 8.70767C16.1358 8.65492 16.2162 8.57366 16.2667 8.47488C16.3171 8.37611 16.3352 8.26459 16.3185 8.15545C16.3018 8.04631 16.2511 7.94482 16.1732 7.86472C16.0954 7.78462 15.9941 7.7298 15.8833 7.70767Z" fill="white"/>
          </g>
          <defs>
          <clipPath id="clip0_243_312">
          <rect width="12.8205" height="12.5" fill="white" transform="translate(3.58984 5.49963)"/>
          </clipPath>
          </defs>
        </svg>
      `)}`,
      scaledSize: new globalThis.google.maps.Size(30, 42), // Increase size here
      anchor: new globalThis.google.maps.Point(15, 42),
    };
  }, [isLoaded]);

  // use later after provoder is up and running
  const ProviderPin = React.useMemo(() => {
    if (!isLoaded) return;
    return {
      url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
        <svg width="32" height="46" viewBox="0 0 32 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 45.2414C16 45.2414 0 24.5887 0 15.8345C-3.13096e-08 13.7551 0.413852 11.696 1.21793 9.77489C2.022 7.85376 3.20055 6.10818 4.68629 4.63781C6.17203 3.16744 7.93586 2.00109 9.87707 1.20533C11.8183 0.409571 13.8989 0 16 0C18.1012 0 20.1817 0.409571 22.1229 1.20533C24.0641 2.00109 25.828 3.16744 27.3137 4.63781C28.7994 6.10818 29.978 7.85376 30.7821 9.77489C31.5862 11.696 32 13.7551 32 15.8345C32 24.5887 16 45.2414 16 45.2414ZM15.1795 15.8345C16.3919 15.8345 14.3295 16.6021 15.1868 15.7537C16.0441 14.9053 15.1795 17.0344 15.1795 15.8345C15.1795 14.6346 20.0898 13.4839 19.2325 12.6354C18.3752 11.787 17.2124 11.3103 16 11.3103C14.7876 11.3103 13.6248 11.787 12.7675 12.6354C11.9102 13.4839 15.1795 7.68682 15.1795 8.8867C15.1795 10.0866 20.0658 10.8658 20.9231 11.7143C21.7804 12.5627 13.9671 15.8345 15.1795 15.8345Z" fill="#799AB5"/>
        <mask id="mask0_1984_35762" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="6" y="8" width="20" height="20">
        <rect x="6.40039" y="8.07886" width="19.2" height="19.3892" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_1984_35762)">
        <path d="M14.4012 20.1971V23.4287C14.4012 23.873 14.2445 24.2534 13.9312 24.5698C13.6178 24.8862 13.2412 25.0444 12.8012 25.0444C12.3612 25.0444 11.9845 24.8862 11.6712 24.5698C11.3578 24.2534 11.2012 23.873 11.2012 23.4287V12.1183C11.2012 11.674 11.3578 11.2936 11.6712 10.9772C11.9845 10.6608 12.3612 10.5026 12.8012 10.5026H16.8012C18.1345 10.5026 19.2678 10.9738 20.2012 11.9164C21.1345 12.8589 21.6012 14.0034 21.6012 15.3499C21.6012 16.6963 21.1345 17.8408 20.2012 18.7834C19.2678 19.7259 18.1345 20.1971 16.8012 20.1971H14.4012ZM14.4012 16.9656H16.9612C17.4012 16.9656 17.7778 16.8074 18.0912 16.491C18.4045 16.1746 18.5612 15.7942 18.5612 15.3499C18.5612 14.9055 18.4045 14.5251 18.0912 14.2087C17.7778 13.8923 17.4012 13.7341 16.9612 13.7341H14.4012V16.9656Z" fill="white"/>
        </g>
        </svg>
      `)}`,
      scaledSize: new globalThis.google.maps.Size(30, 42), // Increase size here
      anchor: new globalThis.google.maps.Point(15, 42),
    };
  }, [isLoaded]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  // Basically this function is to calculate the distance between the user's current location and the selected airport
  const handleNavigateToLocation = () => {
    if (selectedAirport && mapRef.current) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          const directionsService = new google.maps.DirectionsService();
          directionsService.route(
            {
              origin: currentLocation,
              destination: { lat: selectedAirport.lat, lng: selectedAirport.lng },
              travelMode: google.maps.TravelMode.DRIVING,
              drivingOptions: { departureTime: new Date() },
            },
            (result, status) => {
              if (status === google.maps.DirectionsStatus.OK && result) {
                if (!directionsRendererRef.current) {
                  directionsRendererRef.current =
                    new google.maps.DirectionsRenderer();
                  directionsRendererRef.current.setMap(mapRef.current);
                }
                directionsRendererRef.current.setDirections(result);

                const route = result.routes?.[0]?.legs?.[0];
                // include the traffic condition in the calculation
                const durationInSeconds =
                  route?.duration_in_traffic?.value || route?.duration?.value || 0;

                const eta = new Date();
                eta.setSeconds(eta.getSeconds() + durationInSeconds);

                setTravelInfo({
                  distance: route?.distance?.text || 'N/A',
                  duration:
                    route?.duration_in_traffic?.text ||
                    route?.duration?.text ||
                    'N/A',
                  eta: eta.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  }),
                });

                // eslint-disable-next-line unicorn/no-useless-undefined
                onItemSelect(undefined);
              } else {
                setError('Failed to calculate directions.');
              }
            }
          );
        },
        error => setError(`Error getting current location: ${error.message}`)
      );
    }
  };

  const handleSelection = (item: AirportGoogleMapData | undefined) => {
    onItemSelect(item);

    if (mapRef.current) {
      if (item) {
        mapRef.current.panTo({ lat: item.lat, lng: item.lng });
        mapRef.current.setZoom(10);
      } else {
        mapRef.current.panTo(center);
        mapRef.current.setZoom(center.zoom);
      }
    }
  };

  const handleCancelNavigation = () => {
    if (mapRef.current) {
      setTravelInfo(undefined);

      if (directionsRendererRef.current) {
        // eslint-disable-next-line unicorn/no-null
        directionsRendererRef.current.setMap(null); // I have to disable it because of the default value is null and esLint always complains
        directionsRendererRef.current = undefined;
      }

      mapRef.current.panTo(center);
      mapRef.current.setZoom(6);
    }
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {error && (
        <div className="absolute top-5 left-5 bg-red-500 text-white p-4 rounded-lg shadow-md z-10">
          <p>{error}</p>
        </div>
      )}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={center.zoom || 6}
        options={mapOptions}
        onLoad={map => {
          mapRef.current = map; // Store map instance in a ref
        }}
      >
        {filteredAirports?.map(item => (
          <Marker
            key={item.name}
            position={{ lat: item.lat, lng: item.lng }}
            icon={pinIcon as google.maps.Icon}
            onClick={() => handleSelection(item)}
          />
        ))}

        {selectedAirport && (
          <InfoWindow
            position={{
              lat: selectedAirport.lat,
              lng: selectedAirport.lng,
            }}
            // eslint-disable-next-line unicorn/no-useless-undefined
            onCloseClick={() => handleSelection(undefined)}
          >
            <div className="flex flex-col items-center p-2 lg:p-4 bg-white rounded-lg shadow-lg">
              <h4 className="text-lg text-center mb-1">{selectedAirport.name}</h4>
              <p className="text-lg text-center mb-4">{nearestCountry}</p>
              <Button onClick={handleNavigateToLocation} variant="primary">
                Go to this location
              </Button>
            </div>
          </InfoWindow>
        )}
        {/* The warning below can't be fixed due to the prettier always formating the code */}
        {providerData?.map(provider => (
          <Marker
            key={provider.name}
            position={{ lat: provider.lat, lng: provider.lng }}
            icon={{
              url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
        <svg width="93" height="69" viewBox="0 0 93 69" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_dd_2654_121)">
            <path d="M11 19C11 12.3726 16.3726 7 23 7H70C76.6274 7 82 12.3726 82 19V35C82 41.6274 76.6274 47 70 47H23C16.3726 47 11 41.6274 11 35V19Z" 
              fill="${
                selectedProvider?.name === provider.name
                  ? '#014E7E'
                  : hoveredProvider?.name === provider.name
                    ? '#12A3FD'
                    : '#3E4451'
              }"
            />
            <path d="M52.5 47H40.5L45.7474 52.9971C46.1458 53.4524 46.8542 53.4524 47.2526 52.9971L52.5 47Z" 
              fill="${
                selectedProvider?.name === provider.name
                  ? '#014E7E'
                  : hoveredProvider?.name === provider.name
                    ? '#12A3FD'
                    : '#3E4451'
              }"
            />
            <text x="50%" y="30" font-size="16" fill="white" font-weight="regular" text-anchor="middle" alignment-baseline="middle">
              €${provider.price?.toFixed(2) ?? '0.00'}
            </text>
          </g>
          <defs>
            <filter id="filter0_dd_2654_121" x="0" y="0" width="93" height="68.8571" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="1"/>
              <feGaussianBlur stdDeviation="1.5"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2654_121"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feMorphology radius="3" operator="dilate" in="SourceAlpha" result="effect2_dropShadow_2654_121"/>
              <feOffset dy="4"/>
              <feGaussianBlur stdDeviation="4"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
              <feBlend mode="normal" in2="effect1_dropShadow_2654_121" result="effect2_dropShadow_2654_121"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2654_121" result="shape"/>
            </filter>
          </defs>
        </svg>
      `)}`,
              scaledSize: new google.maps.Size(93, 69),
              anchor: new google.maps.Point(46.5, 53),
            }}
            onClick={() => setSelectedProvider(provider)}
            onMouseOver={() => setHoveredProvider(provider)}
            onMouseOut={() => setHoveredProvider(undefined)}
            animation={google.maps.Animation.DROP}
          />
        ))}

        {/* Tooltip when hovering */}
        {hoveredProvider && (
          // use overlay view insead of InfoWIndow, finally found it
          <OverlayView
            position={{ lat: hoveredProvider.lat, lng: hoveredProvider.lng }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} // Ensures it appears above markers
          >
            <div
              className="bg-gray-600 text-white px-6 py-2 rounded-xl shadow-[2px_2px_10px_rgb(0,0,0,0.40)] text-sm font-regular"
              style={{
                transform: 'translate(-50%, -240%)', // ⬆ Moved higher (adjust this value as needed)
                pointerEvents: 'none', // Non-interactive
                position: 'absolute', // Required for OverlayView
                whiteSpace: 'nowrap', // Prevents text wrapping
              }}
            >
              {hoveredProvider.name}
            </div>
          </OverlayView>
        )}

        {selectedProvider && (
          <div className="absolute bottom-4 left-4 z-50 w-72 rounded-xl shadow-[0_8px_30px_rgb(0,0,0,0.12)] bg-white p-4">
            <div className="flex justify-between items-center">
              <h4 className="text-lg font-regular text-gray-800">
                {selectedProvider.name}
              </h4>
              <Button
                icon="CloseRoundedIcon"
                iconColor="blueGray"
                variant="link"
                size="small"
                onClick={() => setSelectedProvider(undefined)}
              />
            </div>

            <div className="flex gap-2 text-sm text-gray-600 my-1">
              <span>Top Rated</span> • <span>Best price</span>
            </div>

            <div className="flex items-center mt-1">
              {Array.from({ length: 4 }).map((_, index) => (
                <Icon key={index} name="StarIcon" iconColor="yellow" size={4} />
              ))}
              <Icon name="StarHalfIcon" iconColor="yellow" size={4} />
              <span className="text-gray-800 font-regular ml-2">9.3</span>
              <span className="text-gray-500 text-sm ml-1">(464)</span>
            </div>

            <div className="flex items-center gap-2 mt-2 text-gray-700 text-sm">
              (logo) <span>Shuttle (12 minutes)</span>
            </div>

            <div className="flex justify-between items-center mt-4">
              <Button>More Info</Button>
              <span className="text-lg font-bold text-gray-800">
                €{selectedProvider.price?.toFixed(2)}
              </span>
            </div>
          </div>
        )}

        {providerOptionData?.map(item => (
          <Marker
            key={item.name}
            position={{ lat: item.lat, lng: item.lng }}
            icon={{
              url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
        <svg width="32" height="46" viewBox="0 0 32 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 45.2414C16 45.2414 0 24.5887 0 15.8345C-3.13096e-08 13.7551 0.413852 11.696 1.21793 9.77489C2.022 7.85376 3.20055 6.10818 4.68629 4.63781C6.17203 3.16744 7.93586 2.00109 9.87707 1.20533C11.8183 0.409571 13.8989 0 16 0C18.1012 0 20.1817 0.409571 22.1229 1.20533C24.0641 2.00109 25.828 3.16744 27.3137 4.63781C28.7994 6.10818 29.978 7.85376 30.7821 9.77489C31.5862 11.696 32 13.7551 32 15.8345C32 24.5887 16 45.2414 16 45.2414ZM15.1795 15.8345C16.3919 15.8345 14.3295 16.6021 15.1868 15.7537C16.0441 14.9053 15.1795 17.0344 15.1795 15.8345C15.1795 14.6346 20.0898 13.4839 19.2325 12.6354C18.3752 11.787 17.2124 11.3103 16 11.3103C14.7876 11.3103 13.6248 11.787 12.7675 12.6354C11.9102 13.4839 15.1795 7.68682 15.1795 8.8867C15.1795 10.0866 20.0658 10.8658 20.9231 11.7143C21.7804 12.5627 13.9671 15.8345 15.1795 15.8345Z" fill="#799AB5"/>
        <mask id="mask0_1984_35762" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="6" y="8" width="20" height="20">
        <rect x="6.40039" y="8.07886" width="19.2" height="19.3892" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_1984_35762)">
        <path d="M14.4012 20.1971V23.4287C14.4012 23.873 14.2445 24.2534 13.9312 24.5698C13.6178 24.8862 13.2412 25.0444 12.8012 25.0444C12.3612 25.0444 11.9845 24.8862 11.6712 24.5698C11.3578 24.2534 11.2012 23.873 11.2012 23.4287V12.1183C11.2012 11.674 11.3578 11.2936 11.6712 10.9772C11.9845 10.6608 12.3612 10.5026 12.8012 10.5026H16.8012C18.1345 10.5026 19.2678 10.9738 20.2012 11.9164C21.1345 12.8589 21.6012 14.0034 21.6012 15.3499C21.6012 16.6963 21.1345 17.8408 20.2012 18.7834C19.2678 19.7259 18.1345 20.1971 16.8012 20.1971H14.4012ZM14.4012 16.9656H16.9612C17.4012 16.9656 17.7778 16.8074 18.0912 16.491C18.4045 16.1746 18.5612 15.7942 18.5612 15.3499C18.5612 14.9055 18.4045 14.5251 18.0912 14.2087C17.7778 13.8923 17.4012 13.7341 16.9612 13.7341H14.4012V16.9656Z" fill="white"/>
        </g>
        </svg>
      `)}`,
            }}
            onClick={() => setSelectedProviderOption(item)}
          />
        ))}

        {selectedProviderOption && (
          <OverlayView
            position={{
              lat: selectedProviderOption.lat,
              lng: selectedProviderOption.lng,
            }}
            mapPaneName={OverlayView.FLOAT_PANE}
          >
            <div
              className="bg-white shadow-lg rounded-lg relative p-4 w-64"
              style={{
                // need to do manual positioning based on the pin (google maps)
                transform: 'translate(-50%, -130%)', //  Moves above the pin
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
              }}
            >
              {/* Header Section */}
              <div className="flex justify-between items-center">
                <h4 className="text-lg font-medium text-gray-800">
                  {selectedProviderOption.name}
                </h4>
                <Button
                  icon="CloseRoundedIcon"
                  iconColor="blueGray"
                  variant="link"
                  size="small"
                  iconSize={6}
                  onClick={() => setSelectedProviderOption(undefined)}
                />
              </div>

              {/* Badge Section */}
              <div className="flex gap-2 text-sm text-gray-600 my-1">
                <span>Top Rated</span> • <span>Best price</span>
              </div>

              <div className="flex items-center mt-1">
                {Array.from({ length: 4 }).map((_, index) => (
                  <Icon key={index} name="StarIcon" iconColor="yellow" size={4} />
                ))}
                <Icon name="StarHalfIcon" iconColor="yellow" size={4} />
                <span className="text-gray-800 text-sm font-regular ml-2">9.3</span>
                <span className="text-gray-500 text-sm ml-1">(464)</span>
              </div>

              {/* Shuttle Info */}
              <div className="flex items-center gap-2 mt-2 text-gray-700 text-sm">
                (logo) <span>Shuttle (12 minutes)</span>
              </div>

              {/* Footer Section */}
              <div className="mt-4">
                <Button>More Info</Button>
              </div>
            </div>
          </OverlayView>
        )}
      </GoogleMap>
      {travelInfo && (
        <div className="absolute bottom-5 left-5 bg-white p-4 rounded-lg shadow-md text-sm lg:text-lg">
          <h4 className="font-semibold">Travel Information</h4>
          <p>Distance: {travelInfo.distance}</p>
          <p>Duration: {travelInfo.duration}</p>
          <p>ETA: {travelInfo.eta}</p>
          <Button onClick={handleCancelNavigation} variant="cancel">
            Cancel Navigation
          </Button>
        </div>
      )}
    </>
  );
};
