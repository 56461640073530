import { z } from 'zod';

const envSchema = z.object({
  NEXT_PUBLIC_SITE_API_ENDPOINT: z.string(),
  NEXT_PUBLIC_SITE_GOOGLE_MAPS_API: z.string(),
  NEXT_PUBLIC_SITE_REDIRECT_URI: z.string(),
  NEXT_PUBLIC_SITE_CLIENT_ID: z.string(),
  NEXT_PUBLIC_SITE_GTAG_ID: z.string(),
  NEXT_PUBLIC_SITE_SCOPE: z.string(),
  NEXT_PUBLIC_SITE_RESPONSE_TYPE: z.string(),
  NEXT_PUBLIC_SITE_AUTH_ENDPOINT: z.string(),
  NEXT_PUBLIC_SITE_LOGOUT_ENDPOINT: z.string(),
  NEXT_PUBLIC_SITE_ENV: z.union([
    z.literal('development'),
    z.literal('production'),
    z.literal('test'),
  ]),
});

const parsed = envSchema.safeParse({
  NEXT_PUBLIC_SITE_API_ENDPOINT: process.env.NEXT_PUBLIC_SITE_API_ENDPOINT,
  NEXT_PUBLIC_SITE_REDIRECT_URI: process.env.NEXT_PUBLIC_SITE_REDIRECT_URI,
  NEXT_PUBLIC_SITE_CLIENT_ID: process.env.NEXT_PUBLIC_SITE_CLIENT_ID,
  NEXT_PUBLIC_SITE_GOOGLE_MAPS_API: process.env.NEXT_PUBLIC_SITE_GOOGLE_MAPS_API,
  NEXT_PUBLIC_SITE_GTAG_ID: process.env.NEXT_PUBLIC_SITE_GTAG_ID,
  NEXT_PUBLIC_SITE_SCOPE: process.env.NEXT_PUBLIC_SITE_SCOPE,
  NEXT_PUBLIC_SITE_RESPONSE_TYPE: process.env.NEXT_PUBLIC_SITE_RESPONSE_TYPE,
  NEXT_PUBLIC_SITE_AUTH_ENDPOINT: process.env.NEXT_PUBLIC_SITE_AUTH_ENDPOINT,
  NEXT_PUBLIC_SITE_LOGOUT_ENDPOINT: process.env.NEXT_PUBLIC_SITE_LOGOUT_ENDPOINT,
  NEXT_PUBLIC_SITE_ENV: process.env.NEXT_PUBLIC_SITE_ENV,
});

if (!parsed.success) {
  throw new Error(`❌ Invalid environment variables: ${parsed.error.toString()}`);
}

export const env = parsed.data;
